import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, Col, Input, Row } from 'reactstrap'
import { CustomerService } from '../../modules/Services/Customers'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { Icon } from '@iconify/react';
import { FilterDetails } from '../../App'
import ReactSelect from 'react-select'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import InvoiceModel from '../Invoices/InvoiceModel'
import { JobService } from '../../modules/Services/Job'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import UILoader from '../../modules/commonModel/Ui-loader'
import CustomBadge from '../../modules/commonModel/CustomBadge'

type Props = {
    customer_id: any;
    renderElementService: number;
    setRenderElementService: React.Dispatch<React.SetStateAction<number>>;
};

const CustomerBookingDetail: React.FC<Props> = ({ customer_id, renderElementService, setRenderElementService }) => {
    const entity_id = localStorage.getItem('entity_id')
    const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
    const [customerBookingData, setCustomerBookingData] = useState([])
    const [pending, setPending] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    // const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [changeCheckbox, setChangeCheckbox] = useState([])
    const [currentSelected, setCurrentSelected] = useState({ value: null, label: 'With Selected' })
    const [openInvoice, setOpenInvoice] = useState(false)
    const [statusData, setStatusData] = useState([])
    const [currentStatus, setCurrentStatus] = useState({ value: '', label: 'Select Status' })
    const [openAccordian, setOpenAccordian] = useState('');
    const navigate = useNavigate()

    const toggle = (id: any) => {
      if (openAccordian === id) {
        setOpenAccordian('');
      } else {
        setOpenAccordian(id);
      }
    };

    const withSelectedData = [
        { value: null, label: 'With Selected' },
        { value: 'invoice', label: 'Invoice' }
    ]    

    const getBookingData = () => {
        setPending(true)
        const data = {
            per_page: 15,
            page: currentPage,
            job_status: currentStatus?.value ? currentStatus?.value : null
        }
        CustomerService.getBookingsByCustomer(entity_id, customer_id, data)
        .then((res: any) => {
            if (currentPage > 1) setCustomerBookingData(pre => [...pre, ...res?.data?.response?.data]) 
            else setCustomerBookingData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    useEffect(() => {
        if (renderElementService && customer_id) {
          setRenderElementService(0)
          getBookingData()
        }
    }, [renderElementService])

    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

    function getStatusList() {
        JobService.getJobStatusList(entity_id)
        .then((res: any) => {
          const customerList = res?.data?.response?.map((item: any) => {
            return { value: item?.id, label: item?.label }
          })
            const dafault = { value: '', label: 'Select Status'}
            setStatusData([dafault, ...customerList])
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
        })
    }

    useEffect(() => {
        getStatusList()
    }, [])

    function scrollTop() {
        const table = document.getElementById('customer_booking_table')
        table.scrollTo(0, 0)
        setCurrentPage(1)
        setRenderElementService(1)
    }

    function changeper(id: any, value: any) {
        let UpdateList = [...changeCheckbox]
        if (value) {
            UpdateList = [...changeCheckbox, id]
        } else {
            UpdateList.splice(changeCheckbox.indexOf(id), 1)
        }
        setChangeCheckbox(UpdateList)
    }

    function allCheckboxChange(value: any) {
        if (value) {
            const data = []
            customerBookingData?.map((item: any) => {
                if (item?.job_status?.id !== 2) data.push(item?.id)
            })
            setChangeCheckbox(data)
        } else {
            setChangeCheckbox([])
        }
    }

    const [bookingData, setBookingData] = useState([])

    useEffect(() => {
        if (customerBookingData?.length) {
            const array = []
            customerBookingData?.map((item) => {
                if (changeCheckbox.includes(item?.id)) {
                    array.push(item)
                }
            })
            setBookingData(array)
        }
    }, [changeCheckbox])
    
    return (
        <Fragment>
             <InvoiceModel
                openInvoice={openInvoice}
                setOpenInvoice={setOpenInvoice}
                bookingData={bookingData}
                setRenderElementService={setRenderElementService}
            />
            <UILoader blocking={pending} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                <div className='mb-5 ms-5 d-flex'>
                    <div className='d-flex align-items-center'>
                        <Input type='checkbox' checked={changeCheckbox?.length ? (changeCheckbox?.length === (customerBookingData?.filter(item => item?.job_status?.id !== 2)?.length)) : false} onChange={(e) => allCheckboxChange(e.target.checked)} />
                    </div>
                    <div style={{width: '200px'}} className='mx-5'>
                        <ReactSelect
                            isClearable={false}
                            isSearchable={false}
                            className='react-select w-100 text-start'
                            classNamePrefix='select'
                            styles={customSelectStyles(null)}
                            theme={selectThemeColors}
                            isDisabled={changeCheckbox?.length === 0}
                            options={withSelectedData}
                            value={currentSelected}
                            onChange={(data: any) => {
                                setCurrentSelected(data)
                                if (data?.value === 'invoice') setOpenInvoice(true)
                            }}
                        />
                    </div>
                    <div style={{width: '150px'}}>
                        <ReactSelect
                            isClearable={false}
                            isSearchable={false}
                            className='react-select w-100 text-start'
                            classNamePrefix='select'
                            styles={customSelectStyles(null)}
                            theme={selectThemeColors}
                            options={statusData}
                            value={currentStatus}
                            onChange={(data: any) => {
                                setCurrentStatus(data)
                                scrollTop()
                            }}
                        />
                    </div>
                </div>
                <div onScroll={(e) => handleScroll(e)} id='customer_booking_table' className='jobs-table-style'>
                    {customerBookingData?.length ? customerBookingData?.map((item: any, i: any) => {
                        return (
                            <Accordion open={openAccordian} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId={i}>
                                        <Row className='w-100 f-flex align-items-center'>
                                            <Col md={1} className='d-flex'>
                                                <div className='d-flex' style={{width: '150px'}}>
                                                    <Input type='checkbox' value={item?.id} checked={changeCheckbox.includes(item?.id)} disabled={item?.job_status?.id === 2} onChange={(e) => changeper(item?.id, e.target.checked)} />
                                                    <h3 className='cursor-pointer theme-color ms-3 mb-0' onClick={() => navigate(`/booking/${item?.id}/edit`)}>
                                                        {item?.booking_number}
                                                    </h3>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className='d-flex justify-content-between'>
                                                    <h3 className='text-gray-700 mb-0'>
                                                        &#8364; {item?.price_by_job ? item?.job_price : item?.job_leg_sum}
                                                    </h3>
                                                    <h5 className='text-gray-700 mb-0'>
                                                        {item?.coachiejoblegs?.length} Jobs
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                            </Col>
                                            <Col md={2}>
                                                <div className='d-flex justify-content-end me-5'>
                                                    <CustomBadge color={item?.job_status?.color} font_color={item?.job_status?.font_color} label={item?.job_status?.label} />
                                                    {/* <h4 className='text-gray-700 py-1 px-2 mb-0' style={{border: '1px solid #4b5675', backgroundColor: '#edf0f1', borderRadius: '5px', maxWidth: 'fit-content'}}>
                                                        {item?.job_status?.label}
                                                    </h4> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={i}>
                                        {item?.coachiejoblegs?.map((data: any, index: any) => {
                                            return (
                                                <Row key={index} className='mt-5 ms-6'>
                                                    <Col xs={1}>
                                                        <p style={{marginBottom: '5px'}} className='cursor-pointer fw-bold theme-color' onClick={() => navigate(`/jobs/${data?.id}/edit`)}>
                                                            {data?.job_number}
                                                        </p>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <p style={{marginBottom: '5px'}}>
                                                            {data?.pick_up_location &&
                                                                <>
                                                                    <Icon icon="bi:pin-map-fill" style={{color: '#f68716'}} /> 
                                                                    <span className='ms-2'>{data?.pick_up_location}</span>
                                                                </>
                                                            }
                                                        </p>
                                                        <p style={{marginBottom: '5px'}}>
                                                            {data?.drop_off_location &&
                                                                <>
                                                                    <Icon icon="bi:arrow-right-circle-fill" style={{color: '#f68716'}} />
                                                                    <span className='ms-2'>{data?.drop_off_location}</span> 
                                                                </>
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <span style={{marginBottom: '5px'}}>
                                                            {data?.start_at_time &&
                                                                <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                                                                    <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                                                                    <span className='ms-2'>{data?.start_at_date ? moment(data?.start_at_date).format("DD-MM-YYYY") : ''} {" "} {data?.start_at_time}</span>
                                                                </div>
                                                            }
                                                        </span>
                                                        <p style={{marginBottom: '5px'}}>
                                                            {data?.end_at_date &&
                                                                <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                                                                    <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                                                                    <span className='ms-2'>{data?.end_at_date ? moment(data?.end_at_date).format("DD-MM-YYYY") : ''} {' '} {data?.end_at_time}</span>
                                                                </div>
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col xs={1}>
                                                        {data?.pax &&
                                                            <>
                                                                <Icon icon="ion:people" style={{color: '#f68716'}} />
                                                                <span className='ms-2'>{data?.pax}</span>
                                                            </>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className='d-flex'>
                                                        {data?.driver_vehicles_arr?.some((item: any) => item?.vehicle?.vehicle_name) &&
                                                            <span>
                                                                <Icon icon="bi:bus-front-fill" style={{color: '#f68716'}} />
                                                            </span>
                                                        }
                                                        <span className='ms-2'>
                                                            {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                                                                return (
                                                                    <div key={i}>{item?.vehicle?.vehicle_name}</div>
                                                                )
                                                            })}
                                                        </span>
                                                    </Col>
                                                    <Col xs={2} className='d-flex'>
                                                        {data?.driver_vehicles_arr?.some((item: any) => item?.driver?.driver_name) &&
                                                            <span>
                                                                <Icon icon="mdi:drivers-license" style={{color: '#f68716'}} />
                                                            </span>
                                                        }
                                                        <span className='ms-2'>
                                                            {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                                                                return (
                                                                    <div key={i}>
                                                                        {item?.driver?.driver_name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </span>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <span>{item?.price_by_job_legs ? data?.cost : ''}</span>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <CustomBadge color={data?.status?.color} font_color={data?.status?.font_color} label={data?.status?.label} />
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>

                            // <Card className='p-5 mb-3' key={i}>
                            //     <Row className='pb-3' style={{borderBottom: '1px solid #F1F1F4'}}>
                            //         <Col md={1} className='d-flex'>
                            //             <div className='d-flex' style={{width: '150px'}}>
                            //                 <Input type='checkbox' value={item?.id} checked={changeCheckbox.includes(item?.id)} disabled={item?.job_status?.id === 2} onChange={(e) => changeper(item?.id, e.target.checked)} />
                            //                 <h3 className='text-gray-700 cursor-pointer ms-3' onClick={() => navigate(`/booking/${item?.id}/edit`)}>
                            //                     {item?.booking_number}
                            //                 </h3>
                            //             </div>
                            //         </Col>
                            //         <Col md={2}>
                            //             <div style={{width: '150px'}}>
                            //                 <h3 className='text-gray-700'>
                            //                     &#8364; {item?.job_price}
                            //                 </h3>
                            //             </div>
                            //         </Col>
                            //         <Col md={8}>
                            //         </Col>
                            //         <Col md={1} style={{width: '120px'}}>
                            //             <h4 className='text-gray-700 py-1 px-2' style={{border: '1px solid #4b5675', backgroundColor: '#edf0f1', borderRadius: '5px', maxWidth: 'fit-content'}}>
                            //                 {item?.job_status?.label}
                            //             </h4>
                            //         </Col>
                            //     </Row>
                                    
                            //     {item?.coachiejoblegs?.map((data: any, index: any) => {
                            //         return (
                            //             <Row key={index} className='mt-5 ms-6'>
                            //                 <Col xs={1}>
                            //                     <p style={{marginBottom: '5px'}} className='cursor-pointer' onClick={() => navigate(`/jobs/${data?.id}/edit`)}>{data?.job_number}</p>
                            //                 </Col>
                            //                 <Col xs={2}>
                            //                     <p style={{marginBottom: '5px'}}>
                            //                         {data?.pick_up_location &&
                            //                             <>
                            //                                 <Icon icon="bi:pin-map-fill" style={{color: '#f68716'}} /> 
                            //                                 <span className='ms-2'>{data?.pick_up_location}</span>
                            //                             </>
                            //                         }
                            //                     </p>
                            //                     <p style={{marginBottom: '5px'}}>
                            //                         {data?.drop_off_location &&
                            //                             <>
                            //                                 <Icon icon="bi:arrow-right-circle-fill" style={{color: '#f68716'}} />
                            //                                 <span className='ms-2'>{data?.drop_off_location}</span> 
                            //                             </>
                            //                         }
                            //                     </p>
                            //                 </Col>
                            //                 <Col xs={2}>
                            //                     <span style={{marginBottom: '5px'}}>
                            //                         {data?.start_at_time &&
                            //                             <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                            //                                 <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                            //                                 <span className='ms-2'>{data?.start_at_date ? moment(data?.start_at_date).format("DD-MM-YYYY") : ''} {" "} {data?.start_at_time}</span>
                            //                             </div>
                            //                         }
                            //                     </span>
                            //                     <p style={{marginBottom: '5px'}}>
                            //                         {data?.end_at_date &&
                            //                             <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                            //                                 <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                            //                                 <span className='ms-2'>{data?.end_at_date ? moment(data?.end_at_date).format("DD-MM-YYYY") : ''} {' '} {data?.end_at_time}</span>
                            //                             </div>
                            //                         }
                            //                     </p>
                            //                 </Col>
                            //                 <Col xs={1}>
                            //                     {data?.pax &&
                            //                         <>
                            //                             <Icon icon="ion:people" style={{color: '#f68716'}} />
                            //                             <span className='ms-2'>{data?.pax}</span>
                            //                         </>
                            //                     }
                            //                 </Col>
                            //                 <Col xs={2} className='d-flex'>
                            //                     {data?.driver_vehicles_arr?.some((item: any) => item?.vehicle?.vehicle_name) &&
                            //                         <span>
                            //                             <Icon icon="bi:bus-front-fill" style={{color: '#f68716'}} />
                            //                         </span>
                            //                     }
                            //                     <span className='ms-2'>
                            //                         {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                            //                             return (
                            //                                 <div key={i}>{item?.vehicle?.vehicle_name}</div>
                            //                             )
                            //                         })}
                            //                     </span>
                            //                 </Col>
                            //                 <Col xs={2} className='d-flex'>
                            //                     {data?.driver_vehicles_arr?.some((item: any) => item?.driver?.driver_name) &&
                            //                         <span>
                            //                             <Icon icon="mdi:drivers-license" style={{color: '#f68716'}} />
                            //                         </span>
                            //                     }
                            //                     <span className='ms-2'>
                            //                         {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                            //                             return (
                            //                                 <div key={i}>
                            //                                     {item?.driver?.driver_name}
                            //                                 </div>
                            //                             )
                            //                         })}
                            //                     </span>
                            //                 </Col>
                            //                 <Col xs={1}>
                            //                     <span>{item?.price_by_job_legs ? data?.cost : ''}</span>
                            //                 </Col>
                            //                 <Col xs={1}>
                            //                     <span className="badge badge-light-success p-2">{data?.status?.label}</span>
                            //                 </Col>
                            //             </Row>
                            //         )
                            //     })}
                            // </Card>
                        )
                    }) : <p className='d-flex justify-content-center mt-2'>There are no records to display</p>}
                </div>
            </UILoader>
        </Fragment>
    )
}

export default CustomerBookingDetail