import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import { LocationService } from '../../modules/Services/Location'
import ReactSelect from 'react-select'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { useLocation, useSearchParams } from 'react-router-dom'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import UILoader from '../../modules/commonModel/Ui-loader'
import { useAuth } from '../../modules/auth'

const AddLocation = ({ setNewLocationData }) => {
  const { control, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm()
  const { currentUser } = useAuth()
  const {location_id} = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams('')
  const [loader, setLoader] = useState(false)
  const [currentCountry, setCurrentCountry] = useState({ value: '', label: 'Select Country'})
  const [countryData, setCountryData] = useState([])
  const [currentCounty, setCurrentCounty] = useState({ value: '', label: 'Select Country'})
  const [countyData, setCountyData] = useState([])
  const [currentAddress1, setCurrentAddress1] = useState('')
  const [editList, setEditList] = useState({ location_country: null, location_county: null })
  const [geo_county, setGeo_county] = useState(null)
  const [geocodeChange, setGeocodeChange] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const entity_id = localStorage.getItem('entity_id')
  const inputRef = useRef()
  const location = useLocation()
  const location_name = searchParams.get('location_name')

  function fetchFormData(data: any) {
    setLoader(true)
    const obj = {
      old_location_name: location_name,
      new_location_name: data?.name,

      // entity_id: entity_id,
      // location_name: data?.name,
      // location_address_1: data?.address_line_1,
      // location_address_1: currentAddress1,
      // location_address_2: data?.address_line_2,
      // location_city: data?.city,
      // location_postcode: data?.postCode,
      // location_country: currentCountry?.value,    
      // location_county: currentCounty?.value    
    }
    // if (location_name) delete obj.entity_id

    LocationService[location_name ? 'updateLocation' : 'storeLocation'](entity_id, obj)
      .then((res: any) => {
        toast.success(res?.data?.message)
        if(location.pathname.split('/')[1] === 'location') {
          navigate('/location')
        } else {
          closeModel()
          setNewLocationData(res?.data?.response)
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function editData() {
    setEditLoader(true)
    LocationService.editLocation(entity_id, location_name)
      .then((res: any) => {
        const obj = {
          name: res.data?.response?.location_name,
          // address_line_1: res.data?.response?.location_address_1,
          address_line_2: res.data?.response?.location_address_2,
          city: res.data?.response?.location_city,
          postCode: res.data?.response?.location_postcode,
          country: '',
          county: ''
        }
        setCurrentAddress1(res.data?.response?.location_address_1)
        setEditList(res.data?.response)
        // countryData?.map((item: any) => {
        //   if (Number(res?.data?.response?.location_country) === item?.value) {
        //     obj.country = item
        //     setCurrentCountry(item)
        //   }
        // })
        // countyData?.map((item: any) => {
        //   if (Number(res?.data?.response?.location_county) === item?.value) {
        //     obj.county = item
        //     setCurrentCounty(item)
        //   }
        // })
        reset(obj)
      })
      .catch((err: any) => {
        console.log('err 98', err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (!geocodeChange && countryData?.length >= 0 && countyData?.length >= 0) {
      countryData?.map((item: any) => {
        if (Number(editList?.location_country) === item?.value) {
          setValue('country', item)
          setCurrentCountry(item)
        }
      })
      countyData?.map((item: any) => {
        if (Number(editList?.location_county) === item?.value) {
          setValue('county', item)
          setCurrentCounty(item)
        }
      })
    } else {
      countyData?.map((item: any) => {
        if (geo_county === item?.label) {
          setValue('county', item)
          setCurrentCounty(item)
        }
      })
    }
  }, [editList, countryData, countyData])
  

  function getCountyList() {
    LocationService.getCountyList(entity_id, { country_id: currentCountry?.value })
    .then((res: any) => {
      const countyList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setCountyData(countyList)
    })
    .catch((err: any) => {
      console.log('err 137', err?.response?.data?.message)
    })
  }

  useEffect(() => {
    if (currentCountry?.value) getCountyList()
  }, [currentCountry])

  function getCountryList() {
    LocationService.getCountryList(entity_id)
    .then((res: any) => {
      const countryList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setCountryData(countryList)
    })
    .catch((err: any) => {
      console.log('err 154', err?.response?.data?.message)
    })
  }

  useEffect(() => {
    getCountryList()
  }, [])

  useEffect(() => {
    if (location_name) {
      // editData()
      setValue('name', location_name)
    }
  }, [location_name])

  function handlePlaceChanged() {
    setGeocodeChange(true)
    let data = getValues()
    setCurrentCountry({ value: '', label: 'Select Country'})
    setCurrentCounty({ value: '', label: 'Select County'})
    setGeo_county(null)
    reset({
      name: data.name,
      address_line_2: '',
      city: '',
      postCode: '',
      country: '',
      county: ''
    })

    setTimeout(() => {
      const [place] = (inputRef.current as any)?.getPlaces?.() ?? [];
      if (place) {
        console.log('place', place)
        const addressComponents = place.address_components;
        let geo_city: any, geo_county: any, geo_country: any, geo_post_code: any, geo_addressLine2: any;
        
        addressComponents?.forEach((component: any) => {
          if (component.types.includes('locality')) {
            geo_city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            geo_county = component.long_name.replaceAll('County ', "").trim();
            setGeo_county(geo_county)
          } else if (component.types.includes('country')) {
            geo_country = component.long_name;
          } else if (component.types.includes('postal_code')) {
            geo_post_code = component.long_name;
          } else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2')) {
            geo_addressLine2 = component.long_name;
          }
        });

        const result = place.formatted_address.replaceAll(geo_city, "").replaceAll(geo_county, "").replaceAll(geo_country, "").replaceAll(geo_post_code, "").replaceAll(geo_addressLine2, "").replace('Co.', "").replaceAll(' ,', "").replace(/,\s*$/, "");
        setCurrentAddress1(result)
        
        let obj = getValues()
        obj = {
          name: obj.name,
          address_line_2: geo_addressLine2,
          city: geo_city,
          postCode: geo_post_code,
          country: ''
        }
        countryData?.map((item) => {
          if (geo_country === item?.label) {
            obj.country = item
            setCurrentCountry(item)
          }
        })
        reset(obj)
      }
    }, 100);
  }

  function forceDeleteLocation() {
    setPending(true)
    const data = {
      location_name: location_name
    }
    LocationService.deleteLocation(entity_id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate("/location")
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  function closeModel() {
    localStorage.setItem('closeLocationModal', 'true')
    window.dispatchEvent(new Event("closeLocationModal"))
  }

  return (
    <>
      <ConfirmModal
        isOpen={centeredModal}
        onHide={() => setCenteredModal(false)}
        ondelete={forceDeleteLocation}
        loader={pending}
      />
      <Fragment>
        <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
          <Card className='p-10'>
            <Form onSubmit={handleSubmit(fetchFormData)}>
              <Row>
                <Col md= '6' className='mb-6'>
                  <Label className='form-label' for='name'>Name</Label>
                  <Controller name='name' control={control} rules={{required: true}}
                    render={({ field }) => <Input placeholder='Enter Name' invalid={ errors.name && true } {...field} />} 
                  />
                  {errors.name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                </Col>
                {/* <Col md='6' className='mb-6'>
                  <Label className='form-label' for='address_line_1'>Address Line 1</Label>
                    <LoadScript
                      googleMapsApiKey='AIzaSyCkOF0TyG69mF4cnjlaLz8q-UyYzCGK2JM'
                      libraries={['places']}  
                    >
                      <StandaloneSearchBox
                        onLoad={(ref: any) => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceChanged}
                      >
                        <input type='text' value={currentAddress1} onChange={(e) => setCurrentAddress1(e.target.value)} className='form-control' placeholder='Enter Location' />
                      </StandaloneSearchBox>
                    </LoadScript>                    
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='address_line_2'>Address Line 2</Label>
                  <Controller name='address_line_2' control={control} rules={{required: false}}
                    render={({ field }) => <Input placeholder={"98 Borough bridge Road, Birmingham"} invalid={errors.address_line_2 && true} {...field} />}
                    />
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='city'>City</Label>
                  <Controller name='city' control={control} rules={{required: false}}
                    render={({ field }) => <Input placeholder='City' invalid={errors.city && true} {...field} />}
                  />
                  {errors?.city && (<small className="text-danger ml-2 ">City is a required field</small>)}
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='postCode'>Post Code</Label>
                  <Controller name='postCode' control={control} rules={{ required: false }}
                    render={({ field }) => (
                      <Input invalid={errors.postCode && true} name='postCode' placeholder="SW1A 2AA" styles={errors.eir_code} {...field} />
                    )}
                  />
                  {errors?.postCode && (<small className="text-danger ml-2 ">Post Code is a required field</small>)}
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='country'>Country</Label>
                  <Controller name='country' control={control} rules={{required: false}}
                    render= {({ field: { onChange } }) => (
                      <ReactSelect
                        isClearable={false}
                        className='react-select'
                        classNamePrefix='select'
                        styles={customSelectStyles(null)}
                        theme={selectThemeColors}
                        options={countryData}
                        value={currentCountry}
                        onChange={(data: any) => {
                          onChange(data)
                          setGeocodeChange(true)
                          setCurrentCountry(data)
                          setCurrentCounty({ value: '', label: 'Select Country'})
                          setValue('county', '')
                        }}
                      />
                    )}
                  /> 
                  {errors?.country && (<small className="text-danger ml-2 ">Country is a required field</small>)}
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='county'>County</Label>
                  <Controller name='county' control={control} rules={{required: false}}
                    render= {({ field: { onChange } }) => (
                      <ReactSelect
                        isClearable={false}
                        className='react-select'
                        classNamePrefix='select'
                        styles={customSelectStyles(null)}
                        theme={selectThemeColors}
                        options={countyData}
                        value={currentCounty}
                        onChange={(data: any) => {
                          onChange(data)
                          setCurrentCounty(data)
                        }}
                      />
                    )}
                  /> 
                  {errors?.county && (<small className="text-danger ml-2 ">County is a required field</small>)}
                </Col> */}
              </Row>
              <div className='d-flex justify-content-between mt-10'>
                <div>
                  { currentUser?.level == 1 && location_name &&
                    <Button type="button" color="danger" onClick={() => setCenteredModal(true)} >
                      Delete
                    </Button>
                  }
                </div>
                <div>
                  <Button type="button" color="secondary" onClick={() => (location.pathname.split('/')[1] === 'location' ? navigate("/location") : closeModel())} style={{ marginRight: "10px" }}>
                    Close
                  </Button>
                  <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                    { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </UILoader>
      </Fragment>
    </>
  )
}

export {AddLocation}