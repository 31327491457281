import React, { Fragment, useEffect, useState } from 'react'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { Button, Card, Col, Form, Input, Label, Row, Spinner } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { TagsService } from '../../modules/Services/Tags'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { useAuth } from '../../modules/auth'

function AddTags() {
    const entity_id = localStorage.getItem('entity_id')
    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const { currentUser } = useAuth()
    const { tag_id } = useParams()
    const [centeredModal, setCenteredModal] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('#000000')
    const [fontColor, setFontColor] = useState('#000000')
    const [loader, setLoader] = useState(false)
    const [pending, setPending] = useState(false)
    const [editLoader, setEditLoader] = useState(false)
    const navigate = useNavigate()

    function fetchFormData(data: any) {
        setLoader(true)
        const obj = {
          entity_id: entity_id,
          name: data?.name,
          color: backgroundColor,
          font_color: fontColor
        }
        if (tag_id) delete obj.entity_id
    
        TagsService[tag_id ? 'updateTag' : 'storeTag'](entity_id, obj, tag_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                navigate('/tags')
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(false))
    }
    
    function editData() {
        setEditLoader(true)
        TagsService.editTag(entity_id, tag_id)
            .then((res: any) => {
                const obj = {
                    name: res.data?.name
                }
                setBackgroundColor(res.data?.color)
                setFontColor(res.data?.font_color)
                reset(obj)
            })
            .catch((err: any) => {
                console.log('err 55', err?.response?.data?.message)
            })
            .finally(() => setEditLoader(false))
    }
    
    useEffect(() => {
        if (tag_id) {
            editData()
        }
    }, [tag_id])
    
    function forceDeleteTag() {
        setPending(true)
        TagsService.deleteTag(entity_id, tag_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setCenteredModal(false)
                navigate('/tags')
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setPending(false))
    }

  return (
    <Fragment>
        <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteTag}
            loader={pending}
        />
        <Card className='p-10'>
            <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
                <Form onSubmit={handleSubmit(fetchFormData)}>
                    <Row>
                        <Col md='6' className='mb-6'>
                            <Label className='form-label' for='name'>Name</Label>
                            <Controller name='name' control={control} rules={{required: true}}
                                render={({ field }) => <Input placeholder='Enter Name' invalid={ errors.name && true } {...field} />} 
                            />
                            {errors.name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                        </Col>
                        <Col md='3' className='mb-6'>
                            <Label className='form-label' for='color'>Background Colour</Label>
                            <Input type='color' value={backgroundColor} className='p-0' style={{height: '38px'}} onChange={(e) => setBackgroundColor(e.target.value)}/>
                        </Col>
                        <Col md='3' className='mb-6'>
                            <Label className='form-label' for='color'>Font Colour</Label>
                            <Input type='color' value={fontColor} className='p-0' style={{height: '38px'}} onChange={(e) => setFontColor(e.target.value)}/>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-between'>
                        <div>
                            { currentUser?.level == 1 && tag_id &&
                                <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                    Delete
                                </Button>
                            }
                        </div>
                        <div>
                            <Button type="button" color="secondary" onClick={() => navigate("/tags")} style={{ marginRight: "10px" }}>
                                Close
                            </Button>
                            <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                                { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                            </Button>
                        </div>
                    </div>
                </Form>
            </UILoader>
        </Card>
    </Fragment>
  )
}

export default AddTags