import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import { useAuth } from '../../../modules/auth'
import UILoader from '../../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import ConfirmModal from '../../../modules/commonModel/ConfirmModal'
import { CustomerService } from '../../../modules/Services/Customers'
import PhoneInput from 'react-phone-input-2'

function AddCustomerContact() {

    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const { currentUser } = useAuth()
    const entity_id = localStorage.getItem('entity_id')
    const { customer_id, contact_id } = useParams()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [centeredModal, setCenteredModal] = useState(false)
    const [pending, setPending] = useState('')
    const [officePhone, setOfficePhone] = useState('')
    const addMode = !contact_id
    const EmailPattern = /^[a-zA-Z0-9.'’‘’'_%+-]+@[a-zA-Z0-9.'’‘’'-]+\.[a-z]{0,30}$/

    const phoneStyle = { 
        backgroundColor:"white", 
        backgroundClip:"padding-box", 
        border:"1px solid #d8d6de", 
        borderRadius:"0.357rem",
        width:"100%"
    }

    function fetchFormData(data: any) {
        setLoader(true)
        const obj = {
            entity_id: entity_id,
            contact_name: data?.contact_name,
            contact_email: data?.contact_email,
            contact_number: officePhone,
            contact_role: data?.contact_role,
        }
        if (contact_id) delete obj.entity_id

        CustomerService[contact_id ? 'updateContact' : 'storeContact'](entity_id, obj, customer_id, contact_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                navigate(`/customers/${customer_id}/edit`)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(false))
    }

    function editData() {
        setPending('edit')
        CustomerService.editContact(entity_id, customer_id, contact_id)
            .then((res: any) => {
                const data = res.data?.response
                const obj: any = {
                    contact_name: data?.contact_name,
                    contact_email: data?.contact_email,
                    contact_number: data?.contact_number,
                    contact_role: data?.contact_role
                }
                setOfficePhone(data?.contact_number)
                reset(obj)
            })
            .catch((err: any) => {
                console.log('err 94', err?.response?.data?.message)
            })
            .finally(() => setPending(''))
    }

    useEffect(() => {
        if (contact_id) editData()
    }, [contact_id])

    function forceDeleteContact() {
        setPending('delete')
        CustomerService.deleteContact(entity_id, customer_id, contact_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setCenteredModal(false)
                navigate(`/customers/${customer_id}/edit`)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setPending(''))
    }

    useEffect(() => {
        if (addMode) {
          reset({ mobile: 353 })
          setOfficePhone('353')
        }
    }, [addMode])

    return (
        <Fragment>
            <ConfirmModal
                isOpen={centeredModal}
                onHide={() => setCenteredModal(false)}
                ondelete={forceDeleteContact}
                loader={pending === 'delete'}
            />
            <Card className='p-10'>
                <UILoader blocking={pending === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending === 'edit' ? 'loader-blur' : ''}>
                    <Form onSubmit={handleSubmit(fetchFormData)}>
                        <Row>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='contact_name'>Name</Label>
                                <Controller
                                    name='contact_name'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            type='text'
                                            placeholder='Enter Name'
                                            invalid={errors.name && true}
                                            {...field}
                                        />
                                    }
                                />
                                {errors.first_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='contact_email'>Email</Label>
                                <Controller
                                    name='contact_email'
                                    control={control}
                                    rules={{required : true, pattern: EmailPattern}}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            type='text'
                                            placeholder='Enter Email'
                                            {...field}
                                        />
                                    }
                                />
                                {errors.contact_email?.type === 'required' && (<small className="text-danger ml-2">Email is a required field</small>)}
                                {errors.contact_email?.type === 'pattern' && (<small className="text-danger ml-2">Invalid Email Address</small>)}
                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='contact_number'>Contact Number</Label>
                                <Controller 
                                    name='contact_number' 
                                    control={control} 
                                    defaultValue='' 
                                    rules={{required : false}}
                                    render={({ field: {onChange} }) => ( 
                                        <PhoneInput 
                                            inputStyle={phoneStyle} 
                                            value={officePhone} 
                                            onChange={e => { 
                                                onChange(e); 
                                                setOfficePhone(e) 
                                            }} 
                                        />
                                    )} 
                                />

                            </Col>
                            <Col md='6' className='mb-6'>
                                <Label className='form-label' for='contact_role'>Role</Label>
                                <Controller
                                    name='contact_role'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) =>
                                        <Input
                                            type='text'
                                            placeholder='Enter Role'
                                            {...field}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-between mt-5'>
                            <div>
                                {(currentUser?.level == 1 && contact_id) &&
                                    <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                        Delete
                                    </Button>
                                }
                            </div>
                            <div>
                                <Button type="button" color="secondary" onClick={() => navigate(`/customers/${customer_id}/edit`)} className='me-2'>
                                    Close
                                </Button>
                                <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                                    {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </UILoader>
            </Card>
        </Fragment>
    )
}

export default AddCustomerContact