import axios from "axios"

function getUsersList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}users`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function storeUser(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}users/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function updateUser(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}users/${id}/update`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function editUser(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}users/${id}/edit`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function deleteUser(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.delete(`${import.meta.env.VITE_APP_API_URL}users/${id}/delete`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function userLevelList(entity_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}users/level-dropdown`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function resetPassword(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}users/${id}/user-reset-password`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const UsersService = {
    getUsersList,
    storeUser,
    updateUser,
    editUser,
    deleteUser,
    userLevelList,
    resetPassword
}