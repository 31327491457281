import React, { useEffect, useState } from 'react'
import { Button, CardText, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { JobLegsService } from '../../modules/Services/JobLegs'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import moment from 'moment'

function SmsModel({
    openSmsModel=false,
    setOpenSmsModel,
    changeCheckbox,
    setRenderElementService,
    selectAll,
    currentCustomer,
    statusIds,
    currentDriver,
    jobDate,
    filter_tag,
    searchTerm
}) {
    const entity_id = localStorage.getItem('entity_id')
    const { handleSubmit } = useForm()
    const [notifyData, setNotifyData] = useState([])
    const [betweenDates, setBetweenDates] = useState<any>()
    const [isSendSms, setIsSendSms] = useState(true)
    const [isSendMail, setIsSendMail] = useState(true)
    const [loader, setLoader] = useState(false)

    function fetchFormData() {
        setLoader(true)
        let payload: any = {
            job_ids: selectAll?.all ? 'all' : changeCheckbox,
            isSendSms:isSendSms,
            isSendMail:isSendMail
        }

        if (selectAll?.all) {
            payload = {
                ...payload,
                customer: currentCustomer?.value ? currentCustomer?.value : null,
                filter_status: statusIds?.length ? statusIds : null,
                driver: currentDriver?.value ? currentDriver?.value : null, 
                start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
                end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
                filter_tag: filter_tag?.value ? filter_tag?.value : null,
                search: searchTerm ? searchTerm : null
            }
        }
        JobLegsService.sendSms(entity_id, payload)
        .then((res: any) => {
            setOpenSmsModel(false)
            setRenderElementService(1)
            toast.success(res?.data?.message)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    function getJobDetail() {
        setLoader(true)

        let payload: any = {
            job_ids: selectAll?.all ? 'all' : changeCheckbox
        }

        if (selectAll?.all) {
            payload = {
                ...payload,
                customer: currentCustomer?.value ? currentCustomer?.value : null,
                filter_status: statusIds?.length ? statusIds : null,
                driver: currentDriver?.value ? currentDriver?.value : null, 
                start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
                end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
                filter_tag: filter_tag?.value ? filter_tag?.value : null,
                search: searchTerm ? searchTerm : null
            }
        }

        JobLegsService.getNotifyDeiverDetail(entity_id, payload)
        .then((res: any) => {
            setNotifyData(res?.data?.driversList)
            setBetweenDates(res?.data?.dates)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    useEffect(() => {
        if (openSmsModel) {
            getJobDetail()
        } else {
            setNotifyData([])
            setBetweenDates(null)
            setIsSendSms(true)
            setIsSendMail(true)
        }
    }, [openSmsModel])
    
  return (
    <Modal isOpen={openSmsModel} toggle={() => setOpenSmsModel(false)} className='modal-dialog-centered model-lg smsModel' >
        <ModalHeader className='bg-transparent' toggle={() => { setOpenSmsModel(false) }}>
            <CardText >SMS Notifications for {betweenDates ? betweenDates?.min_date + ' to ' + betweenDates?.max_date : null}</CardText>
        </ModalHeader>
        <Form onSubmit={handleSubmit(fetchFormData)}>
            <ModalBody>
                <Row>
                    {notifyData?.map((item) => {
                        return (
                            <div key={item?.job_id} className='mb-5'>
                                <span>
                                    <strong>{item?.driver_name}</strong> 
                                    <span className='ms-2'>({item?.driver_mobile ? (item?.driver_mobile?.length > 3 ? ("+" + item?.driver_mobile) : '') : ''})</span>
                                </span>
                                {Object.entries(item?.dates).map(([date, jobs]: any) => {
                                    return (
                                        <div key={date}>
                                            <span>{date}</span>
                                            {jobs?.map((job: any, index: any) => (
                                                <div key={index}>
                                                    <span>{job.vehicle}{job.pickup_time ? ',' : ''} {job.pickup_time}{job.pickup_location ? ',' : ''} {job.pickup_location} to {job.dropoff_location}{job.notes ? ',' : ''} {job.notes || ""}</span>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Row>
            </ModalBody>
            <ModalFooter style={{border : "none"}}>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                    <div className="d-flex align-items-center me-4">
                        <Input type="checkbox" className="me-2" checked={isSendSms} onChange={(e) => setIsSendSms(e.target.checked)} />
                        <label className="mb-0">SMS</label>
                    </div>
                    <div className="d-flex align-items-center">
                        <Input type="checkbox" className="me-2" checked={isSendMail} onChange={(e) => setIsSendMail(e.target.checked)} />
                        <label className="mb-0">EMAIL</label>
                    </div>
                </div>
                <div className='d-flex'>
                    <Button color="secondary" onClick={() => setOpenSmsModel(false)}>Cancel</Button>
                    <Button color="primary" type='submit' className="ms-2" disabled={loader} >
                        {loader ? <Spinner size='sm' /> : 'Send'}
                    </Button>
                </div>
            </div>
            </ModalFooter>
        </Form>
    </Modal>
  )
}

export default SmsModel