import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'reactstrap'
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async'
import toast from 'react-hot-toast';
import { CustomerService } from '../../modules/Services/Customers';
import moment from 'moment';
import { InvoiceService } from '../../modules/Services/Invoice';
import ConfirmModal from '../../modules/commonModel/ConfirmModal';
import { Icon } from '@iconify/react';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';
import UILoader from '../../modules/commonModel/Ui-loader';
import { customSelectStyles, formatCurrency, selectThemeColors } from '../../modules/commonModel/Utils';
import ReactSelect from 'react-select'
import InvoicePayment from './InvoicePayment';
import { useAuth } from '../../modules/auth';
import ModifyLineItem from './ModifyLineItem';

function InvoiceModel({
    openInvoice = false,
    setOpenInvoice,
    invoiceId = null,
    setInvoiceId = (null),
    setRenderElementService,
    bookingData
}) {
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({ });
    const { currentUser } = useAuth()
    const entity_id = localStorage.getItem('entity_id')
    const [currentCustomer, setCurrentCustomer] = useState({ value: null, label: 'Select Customer' })
    const [invoiceDate, setInvoiceDate] = useState(new Date())
    const [loader, setLoader] = useState(null)
    const [invoiceData, setInvoiceData] = useState(null)
    const [invoiceItem, setInvoiceItem] = useState([])
    const [centeredModal, setCenteredModal] = useState(false)
    const [invoice_note, setInvoice_note] = useState('')
    const [statusOption, setStatusOption] = useState([])
    const [currentStatus, setCurrentStatus] = useState({ value: null, label: 'Select Status' })
    const [paymentModal, setPaymentModal] = useState(false)
    const [render, setRender] = useState(false)
    const [purchaseOrder, setPurchaseOrder] = useState('')
    const [newCustomerAddress, setNewCustomerAddress] = useState(null)
    const [openModifyLine, setOpenModifyLine] = useState({ open: false, id: null, data: null })
    const [modifyLineData, setModifyLineData] = useState(null)
    const [flag, setFlag] = useState(false)
    let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

    useEffect(() => {
        InvoiceService.getInvoiceStatusList(entity_id)
        .then((res: any) => {
            const data = res?.data?.response?.map((item: any) => {
                return { value: item?.id, label: item?.label }
            })
            setStatusOption(data)
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
    }, [])

    function getInvoiceData() {
        setLoader('editInvoice')
        InvoiceService.editInvoice(entity_id, invoiceId)
        .then((res: any) => {
            setInvoiceData(res?.data?.response)
            setCurrentCustomer({ value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name })
            setInvoiceItem(res?.data?.response?.invoice_items)
            setInvoice_note(res?.data?.response?.invoice_note || '')
            setCurrentStatus({ value: res?.data?.response?.status?.id, label: res?.data?.response?.status?.label })
            modifyLineData({ invoice_text: res?.data?.response, item_text: res?.data?.response})
            
            const date: any = res?.data?.response?.date ? moment(res?.data?.response?.date).format('YYYY-MM-DD') : null
            setInvoiceDate(date)
            reset({
                bill_to_name: { value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name },
                bill_to_email: res?.data?.response?.customer?.customer_email
            })
            setFlag(!flag)
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
        .finally(() => setLoader(null))
    }

    useEffect(() => {
        if (openInvoice) {
            if (invoiceId) {
              getInvoiceData()
            } else {      
                setInvoiceItem(bookingData ? (bookingData?.length ? bookingData : [bookingData]) : [])
                setCurrentCustomer({ value: bookingData?.length ? bookingData[0]?.bill_to_customer_id : bookingData?.bill_to_customer_id, label: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name })
                setPurchaseOrder((bookingData?.length ? bookingData[0]?.purchase_order : bookingData?.purchase_order) || '')
                reset({
                    bill_to_name: { value: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name, label: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name },
                    bill_to_email: bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_email : bookingData?.bill_to_customer?.customer_email
                })
            }
        }
    }, [openInvoice, invoiceId, render])

    const loadOptions = (inputValue: any, callback: any) => {
        if (customerTimeoutId) {
            clearTimeout(customerTimeoutId);
        }
        customerTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,customer_name,customer_email',
                customer_name : inputValue
            }
            CustomerService.getCustomerList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.customer_name, customerEmail: item?.customer_email}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const onSubmit = () => {
        setLoader('submit')
        const jobData = invoiceItem?.map((item: any) => {
            return {
                id: invoiceId ? item?.id : null,
                item_names: item?.coachiejoblegs?.map((data: any) => { return data?.id }),
                booking_id: item?.id,
                price: item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0,
                invoice_text: item?.invoice_text ? item?.invoice_text : null,
                item_text: item?.item_text ? (typeof(item?.item_text) === 'string' ? item?.item_text : item?.item_text[0]) : null
            }
        })

        const totalSum = invoiceItem.reduce((sum, item) => {
            return sum + (item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0)
        },0);

        const payload: any = {
            entity_id: entity_id,
            customer_id: currentCustomer?.value,
            date: moment(invoiceDate).format('YYYY-MM-DD'),
            amount: totalSum,
            invoice_items: jobData,
            invoice_note: invoice_note,
            invoice_purchase_order: purchaseOrder
        }
        if (invoiceId) { 
            payload.status = currentStatus?.value
            delete payload.invoice_items
        }

        InvoiceService[invoiceId ? 'updateInvoice' : 'storeInvoice'](entity_id, payload, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenInvoice(false)
            setRenderElementService(1)
            // setRender(!render)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    useEffect(() => {
        if (!openInvoice) {
            reset({})
            setCurrentCustomer({ value: null, label: 'Select Customer' })
            setCurrentStatus({ value: null, label: 'Select Status' })
            setInvoice_note('')
            setNewCustomerAddress(null)
        }
    }, [openInvoice])

    function forceDeleteInvoice() {
        setLoader('delete')
        InvoiceService.deleteInvoice(entity_id, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setCenteredModal(false)
            setOpenInvoice(false)
            setRenderElementService(1)
            // setRender(!render)
            setInvoiceId(null)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    function getBookingSum() {
        const sumWithInitial = invoiceItem?.reduce((accumulator, currentValue) => accumulator + (currentValue?.price_by_job === 1 ? currentValue?.job_price : currentValue?.price_by_job_legs === 1 ? currentValue?.job_leg_sum : 0), 0)
        return sumWithInitial
    }

    const stripePayment = () => {
        setLoader('stripe_payment')
        InvoiceService.openStripePayment(entity_id, invoiceId)
        .then((res: any) => {
            window.location.href = res?.data?.response?.payment_link;
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const issuePayment = () => {
        setLoader('issue')
        InvoiceService.issuePayment(entity_id, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            getInvoiceData()
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const getCustomerAddress = (data: any) => {
        CustomerService.editCustomer(entity_id, data?.value)
        .then((res: any) => {
            setNewCustomerAddress(res?.data?.response)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const jobsText = (item: any) => {
        const jobsData = item?.map((data: any) => {    
            return (data?.job_number + (data?.start_at_date ? ' ' : '') + (data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : '') + (data?.pick_up_location ? ' ' : '') + (data?.pick_up_location || '') + (data?.drop_off_location ? ' ' : '') + (data?.pick_up_location && data?.drop_off_location ? ' - ' : '') + (data?.drop_off_location ? ' ' : '') + (data?.drop_off_location || '') + (data?.pax ? ' ' : '') + (data?.pax ? (data?.pax + 'pax') : ''))
        })
        return jobsData
    }
    
    useEffect(() => {
        if (modifyLineData && !openModifyLine?.open) {
            invoiceItem?.map((item) => {
                if (item?.id === openModifyLine?.id) {
                    item.invoice_text = modifyLineData?.invoice_text;
                    item.item_text = modifyLineData?.item_text;
                }
            })
            setFlag(!flag)
        }
    }, [modifyLineData])

    return (
    <>
        <Modal isOpen={openInvoice} className="modal-dialog-centered modal-xl" toggle={() => setOpenInvoice(false)} >
            <UILoader blocking={loader === 'editInvoice'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'editInvoice' ? 'loader-blur' : ''}>
                <ModalBody className="px-sm-5">
                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className='pb-5 px-5'>
                                <Col md='12' className='d-flex align-items-center justify-content-between'>
                                    <span className='mb-0' style={{fontSize: '28px'}}>{invoiceData?.invoice_number ? 'Invoice' : 'Create Invoice'}</span>
                                    <h3 className='mb-0' style={{color: '#6C757D'}}>{invoiceData?.invoice_number}</h3>
                                </Col>
                            </Row>
                            <Row className='mt-10 mb-5 px-5'>
                                <Col md='6'>
                                    <div className='mb-5'>
                                        <Label className='form-label' for='bill_to_name'>Bill To</Label>
                                        <Controller name='bill_to_name' control={control} rules={{ required: true }}
                                            render= {({ field: { onChange } }) => (
                                                <AsyncSelect
                                                    isClearable={true}
                                                    className='react-select w-100'
                                                    classNamePrefix='select'
                                                    placeholder='Search Customer'
                                                    isRtl={false}
                                                    name='callback-react-select'
                                                    styles={customSelectStyles(errors?.bill_to_name)}
                                                    theme={selectThemeColors}
                                                    loadOptions={loadOptions}
                                                    value={currentCustomer}
                                                    isDisabled={currentStatus?.value != 1}
                                                    cacheOptions
                                                    onChange={(data: any) => {
                                                        onChange(data)
                                                        setCurrentCustomer(data)
                                                        setValue('bill_to_email', data?.customerEmail)
                                                        if (data?.value) getCustomerAddress(data)
                                                        else setNewCustomerAddress({})
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.bill_to_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                                    </div>
                                    <div>
                                        {!invoiceId &&
                                            <Label className='d-flex align-items-center me-3'>&nbsp;</Label>
                                        }
                                        <Controller name='bill_to_email' control={control} rules={{ required: false }} defaultValue=''
                                            render= {({ field }) => (
                                                <Input placeholder='Enter Email' disabled={true} invalid={errors.bill_to_email && true} {...field} />
                                            )}
                                        />
                                        {errors?.bill_to_email && (<small className="text-danger ml-2 ">Email is a required field</small>)}
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className='mb-5'>
                                        <Label className='d-flex align-items-center me-3'>Invoice Date</Label>
                                        <Flatpickr
                                            className='form-control' 
                                            placeholder='Select Date'
                                            value={invoiceDate}
                                            // disabled={currentStatus?.value != 1}
                                            options={{ 
                                                enableTime: false, 
                                                altInput: true, 
                                                dateFormat: 'Y-m-d H:i',
                                                disableMobile : true
                                            }} 
                                            onChange={(value) => {
                                                setInvoiceDate(value[0])
                                            }}
                                        />
                                    </div>
                                    {invoiceId ?
                                        <div>
                                            <ReactSelect
                                                isDisabled={true}
                                                isClearable={false}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Select Status'
                                                name='callback-react-select'
                                                styles={customSelectStyles(null)}
                                                theme={selectThemeColors}
                                                options={statusOption}
                                                value={currentStatus}
                                                onChange={(data: any) => {
                                                    setCurrentStatus(data)
                                                }}
                                            />
                                        </div>
                                        : 
                                        <div className='mb-5'>
                                            <Label className='d-flex align-items-center me-3'>Purchase Order</Label>
                                            <Input type='text' 
                                                placeholder='Enter Purchase Order' 
                                                value={purchaseOrder} 
                                                onChange={(e) => setPurchaseOrder(e.target.value)} 
                                            />
                                        </div>
                                    }
                                </Col>
                                <Col md='6' className='mt-5'>
                                    {newCustomerAddress ?
                                        <>
                                            <p className='mb-0'>{newCustomerAddress?.customer_address_1}</p>
                                            <p className='mb-0'>{newCustomerAddress?.customer_address_2}</p>
                                            <p className='mb-0'>{newCustomerAddress?.customer_city }</p>
                                            <p className='mb-0'>{newCustomerAddress?.customer_county_name}</p>
                                            <p className='mb-0'>{newCustomerAddress?.customer_country_name}</p>
                                            <p className='mb-0'>{newCustomerAddress?.customer_postcode}</p>
                                        </>
                                    :
                                        (invoiceData?.customer?.customer_full_address || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_full_address : bookingData?.bill_to_customer?.customer_full_address)) ? 
                                            (invoiceData?.customer?.customer_full_address || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_full_address : bookingData?.bill_to_customer?.customer_full_address))?.split(',')?.map((item: any, i: any) => {
                                                return <p className='mb-0' key={i}>{item}</p>
                                            })
                                            : 
                                            <>
                                                <p className='mb-0'>{invoiceData?.customer?.customer_address_1 || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_address_1 : bookingData?.bill_to_customer?.customer_address_1)}</p>
                                                <p className='mb-0'>{invoiceData?.customer?.customer_address_2 || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_address_2 : bookingData?.bill_to_customer?.customer_address_2)}</p>
                                                <p className='mb-0'>{invoiceData?.customer?.customer_city || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_city : bookingData?.bill_to_customer?.customer_city)}</p>
                                                <p className='mb-0'>{invoiceData?.customer?.county_name || (bookingData?.length ? bookingData[0]?.bill_to_customer?.county_name : bookingData?.bill_to_customer?.county_name)}</p>
                                                <p className='mb-0'>{invoiceData?.customer?.countries_name || (bookingData?.length ? bookingData[0]?.bill_to_customer?.countries_name : bookingData?.bill_to_customer?.countries_name)}</p>
                                                <p className='mb-0'>{invoiceData?.customer?.customer_postcode || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_postcode : bookingData?.bill_to_customer?.customer_postcode)}</p>
                                            </>
                                    }
                                </Col>
                                {invoiceId &&
                                    <>
                                        <Col md='6' className='mt-5'>
                                        <Label className='d-flex align-items-center me-3'>Purchase Order</Label>
                                            <p className='mb-0'>{invoiceData?.invoice_purchase_order}</p>
                                        </Col>
                                    </>
                                }
                            </Row>
                            <Row className='px-5'>
                                <div className='job_table mt-3' style={{ maxHeight: '400px', minHeight: '150px', overflowY: 'scroll' }}>
                                    <Table className='w-100 job_table'>
                                        <thead>
                                            <tr>
                                                <th className='ps-5' style={{width: '50%'}}>Booking ID</th>
                                                <th style={{width: '15%'}}>
                                                    <div className='d-flex justify-content-end'>PRICE</div>
                                                </th>
                                                <th style={{width: '15%'}}>
                                                    <div className='d-flex justify-content-end pe-10'>TOTAL</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { invoiceItem?.map((item: any) => {    
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className='pt-5 ps-5'>
                                                                <div className='d-flex' 
                                                                    onClick={() => invoiceId ? currentStatus?.value == 1 ? setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } }) : '' : setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } })}
                                                                >
                                                                    <h6 className='m-0'>{item?.booking_number}</h6>
                                                                    {item?.price_by_job ?
                                                                        <p className={`ms-2 mb-0 ${invoiceId ? '' : 'cursor-pointer'}`}>
                                                                            {item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`}
                                                                        </p>
                                                                    : ''}
                                                                </div>
                                                                {/* <div className='ms-3'>
                                                                    {item?.coachiejoblegs?.map((data: any) => {
                                                                        return (
                                                                            <p style={{marginBottom: "5px"}}>{'- '} ({data?.job_number}) {data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : ''} {data?.pick_up_location} {data?.pick_up_location && data?.drop_off_location && ' - '} {data?.drop_off_location} {data?.pax ? (data?.pax + 'pax') : '' }{item?.price_by_job_legs ? <span>, Charges &#8364; {data?.cost}</span> : ''}</p>
                                                                        )
                                                                    })}
                                                                </div> */}
                                                            </td>
                                                            <td className='ps-5 pb-0'>
                                                                <div className='d-flex justify-content-end'>
                                                                    &#8364; {formatCurrency(item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0)}
                                                                </div>
                                                            </td>
                                                            <td className='pe-10 pb-0'>
                                                                <div className='d-flex justify-content-end'>
                                                                    &#8364; {formatCurrency(item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0)}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        { item?.item_text ? 
                                                            <tr>
                                                                <td className='py-0 ps-10' 
                                                                    onClick={() => invoiceId ? currentStatus?.value == 1 ? setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } }) : '' : setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } })}
                                                                >
                                                                    {item?.item_text}
                                                                </td>
                                                                <td className='py-0'>
                                                                </td>
                                                                <td className='py-0'>
                                                                </td>
                                                            </tr>
                                                        : item?.coachiejoblegs?.map((data: any) => {
                                                            return (
                                                                <tr>
                                                                    <td className='py-0 ps-10' 
                                                                        onClick={() => invoiceId ? currentStatus?.value == 1 ? setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } }) : '' : setOpenModifyLine({ open: true, id: item?.id, data: { invoice_text: item?.invoice_text ? item?.invoice_text : `Total Charges € ${item?.job_price || ''}`, item_text: item?.item_text ? item?.item_text : jobsText(item?.coachiejoblegs) } })}
                                                                    >
                                                                        {'- '} ({data?.job_number}) {data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : ''} {data?.pick_up_location} {data?.pick_up_location && data?.drop_off_location && ' - '} {data?.drop_off_location} {data?.pax ? (data?.pax + 'pax') : '' }
                                                                    </td>
                                                                    <td className='py-0'>
                                                                        <div className='d-flex justify-content-end'>
                                                                            {item?.price_by_job_legs ? data?.cost ? <span>&#8364; {formatCurrency(data?.cost)}</span>  : '' : '' }
                                                                        </div>
                                                                    </td>
                                                                    <td className='py-0'>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div className='mt-4'>
                                                        <p className='d-flex justify-content-end mb-1'>Subtotal</p>
                                                        {invoiceItem[0]?.vat_rate ?
                                                            <p className='d-flex justify-content-end mb-2'>VAT {'(' + (invoiceItem[0]?.vat_rate || 0) + '%)'}</p> : ''
                                                        }
                                                        <h6 className='d-flex justify-content-end'>Total</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='pe-10 mt-4'>
                                                        <p className='d-flex justify-content-end mb-1'>&#8364; {formatCurrency(getBookingSum())}</p>
                                                        {invoiceItem[0]?.vat_rate ?
                                                            <p className='d-flex justify-content-end mb-2'>&#8364; {formatCurrency((getBookingSum() * (invoiceItem[0]?.vat_rate || 0)) / 100)}</p> : ''
                                                        }
                                                        <h6 className='d-flex justify-content-end'>&#8364; {formatCurrency(getBookingSum() + ((getBookingSum() * (invoiceItem[0]?.vat_rate || 0)) / 100))}</h6>
                                                    </div>
                                                </td>
                                            </tr>
                                            <h6 className='ps-5'>Payment</h6>
                                            {invoiceData?.payments?.map((item: any) => {
                                                return (
                                                    <tr>
                                                        <td className='p-0 ps-10'>
                                                            <p className='m-0 fw-bold' style={{color: '#495057'}}>{item?.payment_info}</p>
                                                            <p className='m-0' style={{color: '#495057'}}>{item?.notes}</p>
                                                        </td>
                                                        <td className='p-0'></td>
                                                        <td className='p-0'>
                                                            <p className='d-flex justify-content-end pe-10 m-0'>&#8364; {formatCurrency(item?.amount)}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {invoiceId &&
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <p className='d-flex justify-content-end'>Balance</p>
                                                    </td>
                                                    <td>
                                                        <p className='d-flex justify-content-end pe-10'>&#8364; {invoiceData?.balance ? formatCurrency(invoiceData?.balance) : 0}</p>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                            <Row className='px-5'>
                                <Col md={6}>
                                    <Label className='form-label' for='notes'>Invoice Notes</Label>
                                    <Input 
                                        type='textarea' 
                                        rows='3' 
                                        placeholder='Enter Invoice Notes'
                                        value={invoice_note} 
                                        disabled={invoiceId}
                                        onChange={(e) => setInvoice_note(e.target.value)} 
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-10 p-5'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        { invoiceId && 
                                            <>
                                                {currentUser?.level == 1 && 
                                                    <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                                        Delete
                                                    </Button>
                                                }
                                                {currentStatus?.value == 2 &&
                                                    <>
                                                        <Button type="button" color="primary" className='mx-2' onClick={() => setPaymentModal(true)}>
                                                            Record Payment
                                                        </Button>
                                                        <Button type="button" color="primary"
                                                            disabled={loader === 'stripe_payment'}
                                                            onClick={stripePayment}
                                                        >
                                                            {loader === 'stripe_payment' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Stripe Payment</span>}
                                                        </Button>
                                                    </>
                                                }
                                                {currentStatus?.value == 1 &&
                                                    <Button type="button" color="primary" className='ms-2'
                                                        disabled={loader === 'issue'}
                                                        onClick={issuePayment}
                                                    >
                                                        {loader === 'issue' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Issue</span>}
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div>
                                        <Button type="button" color="secondary" onClick={() => setOpenInvoice(false)} style={{ marginRight: "10px" }}>
                                            Cancel
                                        </Button>
                                        <Button type='submit' disabled={loader === 'submit'} color='primary' className='btn-next'>
                                            { loader === 'submit' ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>{ invoiceId ? 'Update' : 'Create' } Invoice</span>}
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
            </UILoader>
        </Modal>
        <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteInvoice}
            loader={loader === 'delete'}
        />
        <InvoicePayment
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            invoiceId={invoiceId}
            render={render}
            setRender={setRender}
            currentCustomer={currentCustomer}
            setRenderElementService={setRenderElementService}
        />
        <ModifyLineItem
            openModifyLine={openModifyLine}
            setOpenModifyLine={setOpenModifyLine}
            loader={loader === 'modify_line'}
            setModifyLineData={setModifyLineData}
        />
    </>
  )
}

export default InvoiceModel