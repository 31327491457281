/* eslint-disable react-refresh/only-export-components */
import {FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, userInfo} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import axios from 'axios'
import toast from 'react-hot-toast'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const API_URL = import.meta.env.VITE_APP_API_URL;
  const LOGOUT_URL = `${API_URL}logout`;
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    console.log('logout')
    const data = await axios.post<AuthModel>(LOGOUT_URL);
      if (data) {
        saveAuth(undefined)
        setCurrentUser(undefined)
      }
    // saveAuth(undefined)
    // setCurrentUser(undefined)
    // export function login(email: string, password: string) {
      // return axios.post<AuthModel>(LOGIN_URL, {
      //   email,
      //   password,
      // });
    // }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, currentUser, saveAuth, logout, setCurrentUser} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!currentUser) {
          userInfo(localStorage.getItem('entity_id'))
          .then((res: any) => { 
            localStorage.setItem('auth_user', JSON.stringify(res?.data))
            localStorage.setItem('entity_id', '1')
            saveAuth(res?.data)
            setCurrentUser(res?.data)
          })
          .catch((err: any) => {
            logout()

            if (!sessionStorage.getItem('unauthenticated-toast-shown')) {
              sessionStorage.setItem('unauthenticated-toast-shown', 'true')
              toast.error(err?.response?.data?.message)
    
              // Reset the flag after a short delay
              setTimeout(() => {
                sessionStorage.removeItem('unauthenticated-toast-shown')
              }, 3000) // Adjust delay as needed
            }
          })

            // const {data} = await userInfo(apiToken)
          }
      } catch (error) {
        console.error(error)
        if (currentUser) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }
    }

    if (auth) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
