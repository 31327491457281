import { useContext, useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router'

import ComponentSpinner from '../../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { Col, Input, Row, Table } from 'reactstrap'
import UILoader from '../../../modules/commonModel/Ui-loader'
import { FilterDetails } from '../../../App'
import { Icon } from '@iconify/react'
import { CustomerService } from '../../../modules/Services/Customers'

function CustomerContactsTab({ customer_id }) {
    const tableHearder = [
        { label: 'Name', asc_desc: undefined, value: null, style: '' },
        { label: 'Email', asc_desc: undefined, value: null, style: '' },
        { label: 'Contact Number', asc_desc: undefined, value: null, style: '' },
        { label: 'Role', asc_desc: undefined, value: null, style: '' }
    ]

    const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
    const [pending, setPending] = useState(false)
    const navigate = useNavigate()
    const [contactsData, setContactsData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [searchTerm, setSearchTerm] = useState(filterValue?.users?.user_search)
    const [tableHearderField, setTableHearderField] = useState(tableHearder)
    const [sortOrder, setSortOrder] = useState('start_at_date');
    const [sort, setSort] = useState('desc');
    const entity_id = localStorage.getItem('entity_id')

    const getContacts = () => {
        setPending(true)
        CustomerService.getCustomeContactsList(entity_id, customer_id, {
            per_page: 20,
            page: currentPage,
            search: searchTerm ? searchTerm : null
        })
            .then((res: any) => {
                if (currentPage > 1) setContactsData(pre => [...pre, ...res?.data?.response?.data])
                else setContactsData(res?.data?.response?.data)
                setNextPage(res?.data?.response?.next_page_url)
            })
            .catch((err: any) => {
                console.log('err 51', err?.response?.data?.message)
            })
            .finally(() => setPending(false))
    }

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                scrollTop()
            }, 1000);
            return () => clearTimeout(delayDebounceFn)
        } else {
            scrollTop()
        }
    }, [searchTerm])

    function handleSearch(e: any) {
        setSearchTerm(e.target.value)
        setFilterValue({ ...filterValue, customer: { ...filterValue?.customer, contact_search: e.target.value } })
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getContacts()
        }
    }, [renderElementService])

    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

    function scrollTop() {
        const table = document.getElementById('contact_table')
        table.scrollTo(0, 0)
        setCurrentPage(1)
        setRenderElementService(1)
    }

    function onChangeFilter(event: any, fieldText: any) {
        setSortOrder('asc');
        const sort = tableHearderField?.map((item: any) => {
            if (item?.value == fieldText) {
                if (event) return { ...item, asc_desc: false };
                return { ...item, asc_desc: true };
            } else {
                return { ...item, asc_desc: undefined }
            }
        })
        setTableHearderField(sort);
        if (event) setSortOrder('desc');
        setSort(fieldText);
        scrollTop();
    }

    return (
        <KTCard>
            <KTCardBody className='p-0'>
                <div className='invoice-list-table-header w-100 px-10 my-5'>
                    <Row xl="2" lg="2" md="2" sm="1" xs="1">
                        <Col className='d-flex align-items-center p-0' md={6}>
                            <Col md={4}>
                                <Input type='search' placeholder='Search' value={searchTerm} onChange={handleSearch} />
                            </Col>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end p-0' md={6}>
                            <div className="d-flex flex-row align-items-center">
                                <a href='#' className='btn btn-sm btn-primary' onClick={() => navigate(`/customers/${customer_id}/edit/contact`)}>
                                    <KTIcon iconName='plus' className='fs-3' />
                                    New Contact
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <UILoader blocking={pending} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                    <div onScroll={(e) => handleScroll(e)} id='contact_table' className='jobs-table-style'>
                        <Table className='jobs-table'>
                            <thead className='sticky-header'>
                                <tr style={{ fontWeight: '500' }}>
                                    {tableHearderField?.map((item: any, index: any) => {
                                        return (
                                            <th className='ps-3' key={index}>
                                                <div onClick={() => { item?.value ? onChangeFilter(item?.asc_desc, item?.value) : '' }} style={{ cursor: item?.style }}>
                                                    {item.label}
                                                    {item?.value && item.asc_desc !== undefined &&
                                                        <span>
                                                            {item.asc_desc ? <Icon icon="raphael:arrowdown" /> : <Icon icon="raphael:arrowup" />}
                                                        </span>
                                                    }
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {contactsData?.map((item: any, i: any) => {
                                    return (
                                        <tr key={i} style={{ borderBottom: '1px solid rgba(0, 0, 0, .12)' }}>
                                            <td className='ps-3'>
                                                <span onClick={() => navigate(`/customers/${customer_id}/edit/contact/${item?.id}`)} className='fw-bold cursor-pointer fs-6 theme-color'>
                                                    {item?.contact_name}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{item?.contact_email}</span>
                                            </td>
                                            <td>
                                                <span>{item?.contact_number}</span>
                                            </td>
                                            <td>
                                                <span>{item?.contact_role}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {contactsData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no Contacts to display</p>
                            : ''
                        }
                    </div>
                </UILoader>
            </KTCardBody>
        </KTCard>
    )
}

export default CustomerContactsTab