import axios from "axios"

function getInvoiceList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}invoices`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function getClientInvoiceList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}customers/customer-invoice`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function storeInvoice(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}invoices/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function updateInvoice(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.put(`${import.meta.env.VITE_APP_API_URL}invoices/${id}/update`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function editInvoice(entity_id: any, invoice_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}invoices/${invoice_id}/edit`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function deleteInvoice(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.delete(`${import.meta.env.VITE_APP_API_URL}invoices/${id}/delete`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function getInvoiceStatusList(entity_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}invoices/status`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function downloadInvoicePdf(entity_id: any, invoice_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}invoices/invoice-pdf/${invoice_id}`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function downloadInvoiceStatement(entity_id: any, customer_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}customers/${customer_id}/customer-invoice-statement`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function getPaymentStatusList(entity_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}payments/status`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function createPayment(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}payments/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function openStripePayment(entity_id: any, invoice_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}invoices/send-payment-link/${invoice_id}`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function issuePayment(entity_id: any, invoice_id: any) {
    return new Promise((resolve, reject) => {
        axios.put(`${import.meta.env.VITE_APP_API_URL}invoices/${invoice_id}/status-update`, {},{ headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const InvoiceService = {
    getInvoiceList,
    getClientInvoiceList,
    storeInvoice,
    updateInvoice,
    editInvoice,
    deleteInvoice,
    getInvoiceStatusList,
    downloadInvoicePdf,
    downloadInvoiceStatement,
    getPaymentStatusList,
    createPayment,
    openStripePayment,
    issuePayment
}