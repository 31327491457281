import React, { useEffect } from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { useAuth } from '../../modules/auth'

export default function DropdownModal({
    show = false,
    title = '',
    loader = false,
    isEditMode = false,
    onClose = () => {},
    onDelete = () => {},
    onSubmit,
    fields = null
}) {
    const { control, handleSubmit, formState: { errors }, reset } = useForm()
    const { currentUser } = useAuth()

    const keyPress = (e: any) => {
        if (e.key === "Enter") e.preventDefault()
    }

    const handleClose = () => {
        reset({name : ''})
        onClose()
    }

    useEffect(() => {
        console.log('fields', fields)
        if (show) reset({ name : fields?.name, color: fields?.color, font_color: fields?.font_color })
    }, [show])

  return (
    <Modal isOpen={show} className="modal-dialog-centered modal-lg">
        <ModalHeader
            style={{borderBottom: 'none'}}
            toggle={handleClose}
        ></ModalHeader>
        <ModalBody className="p-10 pt-0">
            <div className="text-center mb-10">
                <span className='fs-1 dropdown-sub-header'>{ title }</span>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12} xs={12} className='mb-5'>
                        <Label className="form-label" for="name">Name</Label>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{required: true}}
                            render={({ field }) => (
                                <Input
                                    placeholder="Name"
                                    onKeyDown={(e) => keyPress(e)}
                                    type="text"
                                    invalid={errors.name && true}
                                    {...field}
                                />
                            )}
                        />
                        {errors.name && (
                            <small className="text-danger ml-2">Name is a required field</small>
                        )}
                    </Col>
                    <Col md={3}>
                        <Label className="form-label" for="color">Background Color</Label>
                        <Controller
                            name="color"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <div style={{ height: '27px', width: '170px', borderRadius: '6px', overflow: 'hidden', position: 'relative' }}>
                                    <Input
                                        {...field}
                                        type="color"
                                        className="p-0 cursor-pointer"
                                        style={{ height: '100%', width: '100%', borderColor: 'none' }}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                    <Col md={3}>
                        <Label className="form-label" for="font_color">Font Color</Label>
                        <Controller
                            name="font_color"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <div style={{ height: '27px', width: '170px', borderRadius: '6px', overflow: 'hidden', position: 'relative' }}>
                                    <Input
                                        {...field}
                                        type="color"
                                        className="p-0 cursor-pointer"
                                        style={{ height: '100%', width: '100%', borderColor: 'none' }}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                    <Col xs={12} className="mt-3 pt-10">
                        <div className={isEditMode ? `d-flex justify-content-between` : `d-flex justify-content-end`} >
                            <div className="d-flex justify-content-between">
                                {currentUser?.level == 1 && isEditMode && 
                                    <Button type="button" color="danger" className="btn-next" onClick={() => onDelete()}>
                                        Delete
                                    </Button>
                                }
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button type="button" className="btn-next" onClick={handleClose} >
                                    <span className="align-middle d-sm-inline-block d-none">Cancel</span>
                                </Button>
                                <Button color="primary" type="submit" className="btn-next d-flex align-middle" style={{ marginLeft: `10px` }} disabled={loader} >
                                    <span className="align-middle d-sm-inline-block d-none">
                                        {loader ? <Spinner size='sm' /> : <span>Save</span>}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </ModalBody>
    </Modal>
  )
}
