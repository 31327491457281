import {Fragment, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon,} from '../../../_metronic/helpers'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'
import { CustomerService } from '../../modules/Services/Customers'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { Col, Input, Row, Table } from 'reactstrap'
import { FilterDetails } from '../../App'
import UILoader from '../../modules/commonModel/Ui-loader'

const CustomersPage = () => {
  const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
  const [pending, setPending] = useState(false)
  const navigate = useNavigate()
  const [customerData, setCustomerData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState(filterValue?.customers?.customer_search)
  const [renderElementService, setRenderElementService] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const entity_id = localStorage.getItem('entity_id')

  const getCoustomer = () => {
    setPending(true)
    CustomerService.getCustomerList(entity_id, {
        per_page: 20,
        page: currentPage,
        search: searchTerm ? searchTerm : null
    })
    .then((res: any) => { 
      if (currentPage > 1) setCustomerData(pre => [...pre, ...res?.data?.response?.data]) 
      else setCustomerData(res?.data?.response?.data)
      setNextPage(res?.data?.response?.next_page_url)
    })
    .catch((err: any) => {
      // toast.error(err?.response?.data?.message)
      console.log('err 36', err?.response?.data?.message)
    })
    .finally(() => setPending(false))
  }

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        scrollTop()
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    } else {
      scrollTop()
    }
  }, [searchTerm])

  useEffect(() => {
    if (Object.keys(filterValue?.customers?.customer_search)?.length > 0) {
        let text = ''
        for (const item in filterValue?.customers.customer_search) {
            text += filterValue?.customers.customer_search[item]
        }
        setSearchTerm(text)
    }
  }, [filterValue])

  const handleSearch = (val: any) => {
    setSearchTerm(val)
    setFilterValue({...filterValue, customers: {...filterValue?.customers, customer_search: val }})
  }

  useEffect(() => {
    if (renderElementService) {
      setRenderElementService(0)
      getCoustomer()
    }
  }, [renderElementService])

  function handleScroll(event: any) {
    const scrollHeight = event.target.scrollTop + event.target.clientHeight
    const elementHeight = event.target.scrollHeight
    if (event.target.scrollTop > 0) {
      if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
        setCurrentPage((p: any) => p + 1)
        setRenderElementService((prev) => prev + 1)
      }
    }
  }

  function scrollTop() {
    const table = document.getElementById('booking_table')
    table.scrollTo(0, 0)
    setCurrentPage(1)
    setRenderElementService(1)
  }

  return (
    <>
      <KTCard>
        <KTCardBody className='p-0 pb-5 customer_table'>
          <div className='invoice-list-table-header w-100 my-5 px-10'>
            <Row xl="12" lg="12" md="12" sm="12" xs="12">
              <Col className='d-flex align-items-center justify-content-between p-0'>
                <div className='d-flex align-items-center'>
                  <Input type='search' placeholder='Search' value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <a href='#' className='btn btn-sm btn-primary' onClick={() => navigate('/customers/add')}>
                    <KTIcon iconName='plus' className='fs-3' />
                    New Client
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
            <div onScroll={(e) => handleScroll(e)} id='booking_table' className='jobs-table-style'>
              <Table className='jobs-table'>
                <thead className='sticky-header'>
                  <tr style={{fontWeight: '500'}}>
                    <th className='ps-3'>Client</th>
                    <th>Type</th>
                    <th>Address</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {customerData?.map((item: any, i: any) => {
                    return (
                      <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                        <td  className='ps-3'>
                          <span onClick={() => navigate(`/customers/${item?.id}/edit`)} className='fw-bold cursor-pointer fs-6 theme-color'>
                            { item?.is_company ? item?.company_name : item?.customer_name}
                          </span>
                        </td>
                        <td>
                          <div>{item?.is_company ? 'Company' : 'Individual'}</div>
                        </td>
                        <td>
                          <div>{item?.customer_full_address}</div>
                        </td>
                        <td>
                          <div>{item?.customer_mobile ? (item?.customer_mobile?.length > 3 ? ("+" + item?.customer_mobile) : '') : ''} </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {customerData?.length === 0 ?
                <p className='d-flex justify-content-center'>There are no customers to display</p>
                : ''
              }
            </div>
          </UILoader>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {CustomersPage}
