import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Card, CardBody, CardHeader, CardTitle, Input, Label, Row, Spinner } from 'reactstrap';
import { SettingsService } from '../../modules/Services/Settings';
import toast from 'react-hot-toast';

function StripeConfiguration() {
    const entity_id = localStorage.getItem('entity_id');
    const [stripeKey, setStripeKey] = useState('');
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getStripeSetting()
    }, [])
    
    const getStripeSetting = () => {
        SettingsService.getStripeSetting(entity_id)
        .then((res: any) => {
            setStripeKey(res?.data?.response?.stripe_secret_key)
        })
        .catch((err: any) => console.log('err 21', err?.response?.data?.message))
    }

    const setStripeSetting = () => {
        setLoader(true)
        const data = {
            stripe_secret_key: stripeKey
        }
        SettingsService.saveStripeSetting(entity_id, data)
        .then((res: any) => {
            toast.success(res?.data?.message)
        })
        .catch((err: any) => toast.error(err?.response?.data?.message))
        .finally(() => setLoader(false))
    }
 
    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <CardTitle className='dropdown-header'>Stripe Configuration</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Label className='form-label p-0'>Key</Label>
                        <Input type='text' 
                            placeholder='Enter Key'
                            value={stripeKey}
                            onChange={(e) => setStripeKey(e.target.value)}
                        />
                    </Row>
                    <Row className='mt-10'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" color="primary" className='mx-2 btn btn-sm' disabled={loader} onClick={() => setStripeSetting()}>
                                { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                            </Button>
                        </div>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default StripeConfiguration