
function CustomBadge({ color, font_color, label }) {

    function getAlignedFontColor(backgroundColor: any) {
        let r: any, g: any, b: any;

        // Convert RGB or Hex to RGB values
        if (backgroundColor.startsWith('rgb')) {
            [r, g, b] = backgroundColor.match(/\d+/g).map(Number);
        } else if (backgroundColor.startsWith('#')) {
            const hex = backgroundColor.replace('#', '');
            if (hex.length === 3) {
                r = parseInt(hex[0] + hex[0], 16);
                g = parseInt(hex[1] + hex[1], 16);
                b = parseInt(hex[2] + hex[2], 16);
            } else {
                r = parseInt(hex.slice(0, 2), 16);
                g = parseInt(hex.slice(2, 4), 16);
                b = parseInt(hex.slice(4, 6), 16);
            }
        }

        // Convert RGB to HSL for easier manipulation
        const { h, s, l } = rgbToHsl(r, g, b);

        // Adjust lightness for font color
        const fontLightness = l > 0.5 ? l - 0.4 : l + 0.4; // Ensure sufficient contrast
        const { r: fontR, g: fontG, b: fontB } = hslToRgb(h, s, fontLightness);

        // Convert back to Hex
        return `rgb(${fontR}, ${fontG}, ${fontB})`;
    }

    // Helper Functions
    function rgbToHsl(r: any, g: any, b: any) {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h: any, s: any, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // Achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }

            h /= 6;
        }

        return { h, s, l };
    }

    function hslToRgb(h: any, s: any, l: any) {
        let r: any, g: any, b: any;

        if (s === 0) {
            r = g = b = l; // Achromatic
        } else {
            const hue2rgb = (p: any, q: any, t: any) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        };
    }

    return (
        <span
            className="badge p-2"
            style={{
                backgroundColor: color,
                color: font_color
            }}
         >
            {label}
        </span>
    )
}

export default CustomBadge