import React, { Fragment, useEffect, useRef, useState } from 'react'
import { SettingsService } from '../../modules/Services/Settings'
import toast from 'react-hot-toast'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import DropdownModal from './DropdownModal'
import { Card, CardHeader, CardTitle, Input } from 'reactstrap'
import { ReactSortable } from 'react-sortablejs'
import classNames from 'classnames'
import { Icon } from '@iconify/react';

function JobStatus() {
    const entity_id = localStorage.getItem('entity_id')
    const [jobStatusData, setJobStatusData] = useState([])
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [show, setShow] = useState(null)
    const [loader, setLoader] = useState(null)
    const [render, setRender] = useState(false)
    const updatedListRef = useRef();

    const getJobStatusData = () => {
        setLoader('edit')
        SettingsService.getJobStatusList(entity_id)
            .then((res: any) => {
                setJobStatusData(res?.data?.response)
            })
            .catch((err: any) => console.log('err 26', err?.response?.data?.message))
            .finally(() => setLoader(null))
    }

    useEffect(() => {
        getJobStatusData()
    }, [render])

    const FetchFunction = (data: any) => {
        setLoader('update')
        const formData: any = {
            label: data?.name,
            color: data?.color,
            font_color: data?.font_color
        }

        SettingsService[show?.isEditMode ? 'updateJobStatus' : 'storeJobStatus'](entity_id, formData, show?.id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setShow(null)
                setRender(!render)
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(null))
    }

    const Delete = () => {
        setLoader('delete')
        SettingsService.deleteJobStatus(entity_id, show?.id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setShow(null)
                setConfirmDeleteModal(false)
                setRender(!render)
            })
            .catch((error: any) => toast.error(error?.response?.data?.message))
            .finally(() => setLoader(null))
    }

    function updateSequence(data: any) {
        const payload = {
            dropdown: "job_statuses",
            sequence: data
        }
        SettingsService.bookingStatusUpdateSequence(entity_id, payload)
            .then(() => {
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
    }

  return (
    <Fragment>
        <ConfirmModal
            isOpen={confirmDeleteModal}
            onHide={() => setConfirmDeleteModal(false)}
            ondelete={() => Delete()}
            loader={loader === 'delete'}
        />
        <DropdownModal
            show={show?.isOpen}
            loader={loader === 'update'}
            onClose={() => setShow(null)}
            title={(show?.isEditMode ? `Edit Job Status` : `Add Job Status`)}
            onSubmit={FetchFunction}
            onDelete={() => setConfirmDeleteModal(true)}
            isEditMode={show?.isEditMode}
            fields={show?.fields}
        />
        <Card>
            <CardHeader>
                <CardTitle className='dropdown-header'>Job status</CardTitle>
                {/* <div className='cursor-pointer d-flex align-items-center' onClick={() => setShow({ isOpen: true, id: null, isEditMode: false, fields: {} })}>
                    <Icon icon="ic:baseline-plus" fontSize={20} />
                </div> */}
            </CardHeader>
            <div className="invoice-list-dataTable react-dataTable mb-2">
                {jobStatusData?.length ? (
                    <ReactSortable
                        tag='ul'
                        list={jobStatusData}
                        handle='.drag-icon'
                        className={`todo-task-list p-0 ${jobStatusData.length > 7 && 'table-height'}`}
                        setList={(newList: any) => {
                            setJobStatusData(newList)
                            updatedListRef.current = newList;
                        }}
                        onSort={() => {
                            const dataObj = {}
                            const updatedList = updatedListRef.current || jobStatusData
                            updatedList?.map((item, index) => {
                                dataObj[item.id] = index
                            })
                            updateSequence(dataObj)
                        }}
                    >
                        {jobStatusData?.map(item => {
                            return (
                                <li key={item.id}
                                    className={classNames('todo-item border-bottom px-9 py-4 list-group cursor-pointer')}
                                    onClick={() => {
                                        setShow({ isOpen: true, id: item?.id, isEditMode: true, fields: { name: item?.label, color: item?.color, font_color: item?.font_color } })
                                    }}
                                >
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <div style={{ height: '25px', width: '50px', borderRadius: '6px', overflow: 'hidden', position: 'relative' }}>
                                                <Input
                                                    type="color"
                                                    className="p-0 cursor-pointer"
                                                    readOnly
                                                    value={item?.color}
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ height: '100%', width: '100%', borderColor: 'none' }}
                                                />
                                            </div>
                                            <span className='ms-3 d-flex align-items-center'>{item.label}</span>
                                        </div>
                                        <i className="fa-solid fa-equals drag-icon" style={{ fontSize: '20px' }}></i>
                                    </div>
                                </li>
                            )
                        })}
                    </ReactSortable>
                ) : (
                    <div>
                        <p className="p-1 text-center">There are no Job to display</p>
                    </div>
                )}
            </div>
        </Card>
    </Fragment>
  )
}

export default JobStatus